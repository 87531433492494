<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" @close="Reset" :destroy-on-close="true"
			v-model="outerVisible" title="地图位置">
			<div class="dialog" style="padding: 0;" element-loading-text="地图加载中..." v-loading="loading">
				<div id="amap" class="map"></div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import AMapLoader from '@amap/amap-jsapi-loader'
	export default {
		data() {
			return {
				outerVisible: false,
				map: null,
				marker: [],
				loading: false
			}
		},
		methods: {
			Reset() {
				this.map.clearMap() // 清除所有覆盖物（点标志）
				this.map.remove(this.marker);
				this.marker = []
				this.map && this.map.destroy();
			},
			initMap(address, lat, lng) {
				let that = this
				AMapLoader.load({
					key: "eeaeb42a1835848d7f237bff83299eeb", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					this.map = new AMap.Map("amap", { //设置地图容器id
						viewMode: "2D", //是否为3D地图模式
						zoom: 16, //初始化地图级别
						center: [lng, lat], //初始化地图中心点位置（北京）
						zoomToAccuracy: true
					});
					this.showLocation(address, lng, lat)
				}).catch(e => {
					console.log(e,"报错了");
				})
			},
			//新增标记
			showLocation(address, lng, lat) {
				this.marker = new AMap.Marker({
					position: new AMap.LngLat(lng, lat) //参数为经纬度
				})
				this.map.add(this.marker) // 添加点标志
				this.showInfoWindow(address, this.marker); //自定义信息窗体
			},
			//自定义信息窗体
			showInfoWindow(address) {
				let infoWindow = new AMap.InfoWindow({
					isCustom: true, //是否自定义信息窗体
					content: `<div style="background-color: white;padding:10px; border-radius: 5px;border: 1px solid #cccccc;"> 位置：${address}</div>`,
					closeWhenClickMap: true,
					zIndex: 999,
					offset: new AMap.Pixel(16, -35)
				});
				infoWindow.open(this.map, this.marker.getPosition());
				this.loading = false
			},
			// 显示隐藏
			show(address, lat, lng) {
				this.loading = true
				this.outerVisible = true
				this.initMap(address, lat, lng)
			}
		}
	}
</script>

<style scoped>
	.map {
		width: 100%;
		height: 600px;
	}
</style>